import { useState } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { NavLink as RouterLink, matchPath, useLocation } from 'react-router-dom';
// material
import { alpha, useTheme, styled } from '@mui/material/styles';
import { Box, List, Collapse, ListItemText, ListItemIcon, ListItemButton } from '@mui/material';
//
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.text,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const theme = useTheme();

  const isActiveRoot = active(item.path);

  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);

  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: theme.palette.primary.main_active,
  };

  const activeSubStyle = {
    color: 'text.primary',
    fontWeight: 'fontWeightMedium',
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
            transition: (theme) => theme.transitions.create('transform'),
            // ...(isActiveRoot && {

            //   bgcolor: 'primary.main_active'
            // }),
          }}>
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
          {info && info}
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);
              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}>
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: 'flex',
                        borderRadius: '50%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        bgcolor: 'text.disabled',
                        transition: (theme) => theme.transitions.create('transform'),
                        ...(isActiveSub && {
                          transform: 'scale(2)',
                          bgcolor: 'primary.main_active',
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText
                    disableTypography
                    primary={title}
                    sx={{
                      transition: (theme) => theme.transitions.create('transform'),
                      ...(isActiveSub && {
                        color: 'primary.main_active',
                      }),
                    }}
                  />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}>
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const loggedUser = useSelector((state) => state.auth.loggedUser);
  const userRole = loggedUser?.roles[0].slug;
  console.log('userRolein nav', loggedUser);
  const navConfigArray = [];
  const userPermissions = loggedUser.roles[0].permissions;
  const userRoleaccess = loggedUser.roles[0].role
  console.log("userRoleAccess", userRoleaccess)
  console.log('userPermissions', userPermissions);
  const checkRoles = loggedUser.roles[0].slug;
  const isContainPermission = (name) => {
    let found = false;
    userPermissions?.map((value, index) => {
      if (value.name === name) {
        found = true;
      }
      return null;
    });
    return found;
  };

  const dashObj = {
    title: 'Dashboard',
    path: '/dashboard/home',
    icon: getIcon('eva:pie-chart-2-fill'),
  };
  navConfigArray.push(dashObj);

  if(userRoleaccess !== 'Admin'){
    if (isContainPermission('view-users')) {
      const userDataobj = {
        title: 'Users',
        path: '/dashboard/user',
        icon: getIcon('eva:people-fill'),
    
      }
      navConfigArray.push(userDataobj);
  }
   

  }else{

  const userDataOuterObj = {
    title: 'Users',
    path: '/dashboard/user-data',
    icon: getIcon('eva:people-fill'),
    children: [] 

  };


  if (isContainPermission('view-users')) {
    userDataOuterObj.children.push({
      title: 'All Users',
      path: '/dashboard/user',
      icon: getIcon('eva:people-fill'),
    });
}
  

  if (isContainPermission('view-managers')) {
    userDataOuterObj.children.push({
      title: 'Managers',
      path: '/dashboard/manager',
      icon: getIcon('eva:people-fill'),
    });
  }


  if (userDataOuterObj.children.length !== 0) {
    navConfigArray.push(userDataOuterObj);
  }
}
  if (isContainPermission('view-cutting-tree-application')) {

  const newspaperObj = {
    title: 'Newspaper',
    path: '/dashboard/newspaper',
    icon: getIcon('eva:book-open-fill'), // Change the icon as per your need
  };
  navConfigArray.push(newspaperObj);
}
 
  // if (isContainPermission('view-users')) {
  //   const obj = {
  //     title: 'Users',
  //     path: '/dashboard/user',
  //     icon: getIcon('eva:people-fill'),
  //   };
  //   navConfigArray.push(obj);
  // }
  // if (isContainPermission('view-users')) {
  //   const obj = {
  //     title: 'Managers',
  //     path: '/dashboard/manager',
  //     icon: getIcon('eva:people-fill'),
  //   };
  //   navConfigArray.push(obj);
  // }
  const managerDataOuterObj = {
    title: 'Manage Request',
    path: '/dashboard/manager-data',
    icon: getIcon('material-symbols:settings-account-box-rounded'),
    children: [],
  };
  if (isContainPermission('view-czw-change-requests')) {
    managerDataOuterObj.children.push({
      title: 'Ward-Changes',
      path: '/dashboard/wardChange',
      icon: getIcon('mdi:users-group'),
    });
  }
  if (isContainPermission('view-census-tree-detail-change-requests')) {
    managerDataOuterObj.children.push({
      title: 'Tree Data Changes',
      path: '/dashboard/treeDataChange',
      icon: getIcon('mdi:users-group'),
    });
  }
  if (isContainPermission('view-associate-travel-requests')) {
    managerDataOuterObj.children.push({
      title: 'Travelling Details',
      path: '/dashboard/requesttravelling',
      icon: getIcon('mdi:users-group'),
    });
  }
  if (managerDataOuterObj.children.length !== 0) {
    navConfigArray.push(managerDataOuterObj);
  }

  // if (isContainPermission('view-users')) {
  //   const obj = {
  //     title: 'Request Managers',
  //     path: '/dashboard/requestmanager',
  //     icon: getIcon('mdi:users-group'),
  //   };
  //   navConfigArray.push(obj);
  // }

  if (isContainPermission('view-teams')) {
    const obj = {
      title: 'Teams',
      path: '/dashboard/teams',
      icon: getIcon('bxl:microsoft-teams'),
    };
    navConfigArray.push(obj);
  }

  // if (isContainPermission('view-cutting-tree-application')) {
  //   const obj = {
  //     title: 'Tree Cutting',
  //     path: '/dashboard/tree-cutting',
  //     icon: getIcon('material-symbols:cut'),
  //   };
  //   navConfigArray.push(obj);
  // }

  const treeCuttingOuterObj = {
    title: 'Trees Cutting',
    path: '/dashboard/tree-cuttings',
    icon: getIcon('material-symbols:cut'),
    children: [],
  };

  if (isContainPermission('view-cutting-tree-application')) {
    if (loggedUser.roles[0].slug === 'council') {
      treeCuttingOuterObj.children.push(
        {
          title: 'All Application',
          path: '/dashboard/tree-cutting-application',
          icon: getIcon('eva:shopping-bag-fill'),
        },
        {
          title: 'Upcoming Replanataion',
          path: '/dashboard/upcoming-replantation',
          icon: getIcon('eva:shopping-bag-fill'),
        },
        {
          title: 'Create Meeting',
          path: '/dashboard/create-meeting',
          icon: getIcon('eva:shopping-bag-fill'),
        },
        {
          title: 'View Meeting',
          path: '/dashboard/view-meetings',
          icon: getIcon('eva:shopping-bag-fill'),
        }
      );
    }
  }

  if (treeCuttingOuterObj.children.length !== 0) {
    navConfigArray.push(treeCuttingOuterObj);
  }

  if (isContainPermission('view-teams')) {
    const outerObj = {
      title: 'Master',
      path: '/dashboard/role',
      icon: getIcon('eva:shopping-bag-fill'),
      // children:[]
    };
    navConfigArray.push(outerObj);
  }
  const newReportsObj = {
    title: 'Reports',
    path: '/dashboard/home',
    icon: getIcon('eva:pie-chart-2-fill'),
    children: [],
  };
  newReportsObj.children.push({
    title: 'Working Tree',
    path: '/dashboard/base-color',
    icon: getIcon('eva:shopping-bag-fill'),
  });

  const treeDataOuterObj = {
    title: 'Trees Data',
    path: '/dashboard/tree-data',
    icon: getIcon('bi:tree-fill'),
    children: [],
  };

  if (isContainPermission('view-base-color-trees')) {
    if (loggedUser.roles[0].slug !== 'council') {
      treeDataOuterObj.children.push({
        title: 'Base Color',
        path: '/dashboard/base-color',
        icon: getIcon('eva:shopping-bag-fill'),
      });
    }
  }
  if (isContainPermission('view-census-trees')) {
    const obj = {
      title: 'Census',
      path: '/dashboard/census',
      icon: getIcon('eva:shopping-bag-fill'),
    };
    treeDataOuterObj.children.push(obj);
  }
  if (isContainPermission('base-color-offsite-qc')) {
    const obj = {
      title: 'Base Color QC',
      path: '/dashboard/baseColorPendingQC',
      icon: getIcon('eva:shopping-bag-fill'),
    };
    treeDataOuterObj.children.push(obj);
  }
  if (isContainPermission('census-offsite-qc')) {
    const obj = {
      title: 'Census QC',
      path: '/dashboard/censusQC',
      icon: getIcon('eva:shopping-bag-fill'),
    };
    treeDataOuterObj.children.push(obj);
  }
  // if (isContainPermission('census-offsite-qc')) {
  //   const obj = {
  //     title: 'Census',
  //     path: '/dashboard/census',
  //     icon: getIcon('eva:shopping-bag-fill'),
  //   };
  //   treeDataOuterObj.children.push(obj);
  // }
  if (isContainPermission('view-denied-properties')) {
    if (loggedUser.roles[0].slug !== 'council') {
      const obj = {
        title: 'Denied Entries',
        path: '/dashboard/denied-entry',
        icon: getIcon('eva:shopping-bag-fill'),
      };
      treeDataOuterObj.children.push(obj);
    }
  }
  if (isContainPermission('view-no-tree-properties')) {
    if (loggedUser.roles[0].slug !== 'council') {
      const obj = {
        title: 'No Tree Properties',
        path: '/dashboard/no-tree-properties',
        icon: getIcon('eva:shopping-bag-fill'),
      };
      treeDataOuterObj.children.push(obj);
    }
  }

  if (isContainPermission('view-trees-on-map')) {
    treeDataOuterObj.children.push({
      title: 'Trees on Map',
      path: '/dashboard/treeOnMap',
      icon: getIcon('eva:shopping-bag-fill'),
    });
  }

  if (treeDataOuterObj.children.length !== 0) {
    navConfigArray.push(treeDataOuterObj);
  }

  const newReportObj = {
    title: 'Reports',
    path: '/dashboard/newReports',
    icon: getIcon('eva:file-text-fill'),
    children: [],
  };

  if (isContainPermission('view-work-report')) {
    newReportObj.children.push({
      title: 'Work Reports',
      path: '/dashboard/workingReports',
      icon: getIcon('eva:shopping-bag-fill'),
    });
  }
  if (isContainPermission('view-census-report')) {
    newReportObj.children.push({
      title: 'Census Report',
      path: '/dashboard/census-report',
      icon: getIcon('eva:shopping-bag-fill'),
    });
  }

  if (isContainPermission('view-census-report')) {
    newReportObj.children.push({
      title: 'Work Log Report',
      path: '/dashboard/yesterdayLoggedInAssociates',
      icon: getIcon('eva:shopping-bag-fill'),
    });
  }

  // if (isContainPermission('view-census-report')) {
  //   newReportObj.children.push({
  //     title: 'Call Logs Report',
  //     path: '/dashboard/callLogs',
  //     icon: getIcon('eva:shopping-bag-fill'),
  //   });
  // }

  if (newReportObj.children.length !== 0) {
    navConfigArray.push(newReportObj);
  }

  const newSelfieObj = {
    title: 'Selfie Verification',
    path: '/dashboard/selfie',
    icon: getIcon('eva:image-fill'),
    children: [],
  };

  if (isContainPermission('view-census-report')) {
    newSelfieObj.children.push({
      title: 'All Selfie',
      path: '/dashboard/all-selfie',
      icon: getIcon('eva:shopping-bag-fill'),
    });
  }
  if (isContainPermission('view-census-report')) {
    newSelfieObj.children.push({
      title: 'pending Selfie',
      path: '/dashboard/pending-selfie',
      icon: getIcon('eva:shopping-bag-fill'),
    });
  }

  if (newSelfieObj.children.length !== 0) {
    navConfigArray.push(newSelfieObj);
  }
  const liveLocationOuterData = {
    title: 'User Monitoring',
    path: '',
    icon: getIcon('fluent:location-live-24-filled'),
    children: [],
  };

  if (isContainPermission('view-user-call-logs')) {
    liveLocationOuterData.children.push({
      title: 'Current Location',
      path: '/dashboard/UserLiveLocation',
      icon: getIcon('fluent:location-live-24-filled'),
    });
  }
  if (isContainPermission('view-user-call-logs')) {
    liveLocationOuterData.children.push({
      title: 'Call Logs',
      path: '/dashboard/callLogs',
      icon: getIcon('eva:shopping-bag-fill'),
    });
  }
  if (isContainPermission('view-user-call-logs')) {
    liveLocationOuterData.children.push({
      title: 'Route Travelled',
      path: '/dashboard/routeTravelled',
      icon: getIcon('eva:shopping-bag-fill'),
    });
  }

  if (liveLocationOuterData.children.length !== 0) {
    navConfigArray.push(liveLocationOuterData);
  }
  if (isContainPermission('view-teams')) {
    const outerObj = {
      title: 'Training',
      path: '/dashboard/training',
      icon: getIcon('eva:shopping-bag-fill'),
      // children:[]
    };
    navConfigArray.push(outerObj);
  }

  const match = (path) => (path ? !!matchPath({ path, end: false }, pathname) : false);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfigArray.map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
