import PropTypes from 'prop-types';
// material
import { styled } from '@mui/material/styles';
import {
  Toolbar,
  Tooltip,
  IconButton,
  Typography,
  OutlinedInput,
  InputAdornment,
  Select,
  MenuItem,
  Grid,
  Button,
  TextField,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
// component
import { useSelector } from 'react-redux';
import Iconify from '../../../components/Iconify';
import SearchImage from '../../../Assets/Search_Image.png';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  width: 340,
  height: 44,
  backgroundColor: '#F8F8F8 !important',
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { width: 320, boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

UpcomingReplantationToolbar.propTypes = {
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  onFilterName: PropTypes.func,
  updatedBy : PropTypes.string,
  changeUpdatedBy : PropTypes.func
};

export default function UpcomingReplantationToolbar({
  numSelected,
  filterName,
  onFilterName,
  updatedBy,
  changeUpdatedBy
}) {
  const useStyles = makeStyles({
    icon: {
      fill: '#214C50',
    },
  });
  const classes = useStyles();

  const statusType = [
    {
      id: 'pending',
      name: 'Pending',
    },
    {
      id: 'completed',
      namer: 'Completed',
    },
    {
      id: 'in-progress',
      name: 'In Progress',
    },
  ];

  const filterList = [
    {
      label: 'Site Inspector',
      value: 'site_inspector',
    },
    {
        label: 'Citizen',
        value: 'citizen',
      },
  ];

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}>
      <SearchStyle
        value={filterName}
        onKeyUp={onFilterName}
        // placeholder={placeHolder}
        placeholder="Search..."
        startAdornment={
          <InputAdornment position="start">
            <img src={SearchImage} alt="abell" height="25" width="35" />
            {/* <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} /> */}
          </InputAdornment>
        }
      />

      <Grid container justifyContent="flex-end">
        {/* <Tooltip title="Filter list">
          <IconButton>
            <Iconify icon="ic:round-filter-list" color="#214C50" />
          </IconButton>
        </Tooltip>
        <Grid item xs={3} justifyContent="flex-end">
          <Select
            id="state"
            displayEmpty
            // name="gender"
            value={statusId}
            style={{ width: '100%', height: 45 }}
            onChange={handleStatusChange}
            inputProps={{
              classes: {
                icon: classes.icon,
              },
            }}>
            <MenuItem disabled value="">
              <em>Status</em>
            </MenuItem>
            {statusType?.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                {option.name}
              </MenuItem>
            ))}
          </Select>
        </Grid> */}
          <Grid item sm={4} justifyContent="flex-end">
          <Grid item md={12} sm={12} xs={12}>
              <TextField
                select
                id="updateBy"
                name="updateBy"
                displayEmpty
                label="Update By*"
                value={updatedBy}
                style={{ width: '100%'}}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}
                onChange={(e) => {
                  changeUpdatedBy(e)
                }}
              >
                {filterList?.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>
          </Grid>
      </Grid>
    </RootStyle>
  );
}
