import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useDispatch, useSelector } from 'react-redux';
import { useFormik } from 'formik';
import {
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Chip,
  TableCell,
  Box,
  CardContent,
  MenuItem,
  Select,
  Tooltip,
  TextField,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { AddApplicationToMeeting, GetFutureMeetings } from '../../../actions/MeetingActions';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[800],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired,
};

export default function MeetingListModal(props) {
  const dispatch = useDispatch();
  const { isOpen } = props;
  const [enableAdd, setEnableAdd] = useState(false);
  const [params, setParams] = useState(null);
  const [fullWidth, setFullWidth] = React.useState(true);
  const [maxWidth, setMaxWidth] = React.useState('sm');
  const [submit, setSubmit] = useState(false);
  const [meetingList, setMeetingList] = useState([]);

  const firstRun = React.useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
    }
  }, []);

  useEffect(() => {
    dispatch(GetFutureMeetings()).then((response) => {
      if (response.status === 200) {
        setMeetingList(response.data.data);
      }
    });
  }, [dispatch]);

  const secondRun = React.useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    if (submit) {
      dispatch(AddApplicationToMeeting(params)).then((response)=>{
        
        if(response.status===200) {
          props.handleClose();
        }
      });
    }
  }, [params, submit]);

  const handleSelect = (value) => {
    setParams({
      cutting_tree_meeting_id: value.id,
      cutting_tree_application_id: props.application.applicationId,
    });
    setSubmit(false);
    setEnableAdd(true);
  };

  const handleClick = () => {
    setEnableAdd(false);
    setSubmit(true);
  };

  const handleClose = () => {
    props.handleClose(false);
  };

  const handleTrueClose = () => {
    props.handleClose(true);
  };

  const useStyles = makeStyles({
    icon: {
      fill: '#4CAF50',
    },
    chipSelected: {
      backgroundColor: '#4CAF50',
      color: '#fff',
    },
    chip: {
      backgroundColor: '#3f51b5',
      color: '#000',
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cutting_tree_meeting_id: '',
      cutting_tree_application_id: '',
    },
    onSubmit: (value) => {
      console.log('On Submit :', value);
    },
  });

  const classes = useStyles();
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  return (
    <div>
      <form name="MeetingListModal">
        <Dialog
          fullWidth={fullWidth}
          maxWidth={maxWidth}
          onSubmit={handleSubmit}
          open={isOpen}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
          PaperProps={{
            style: {
              width: '600px',
              height: '450px', // Increase the height here
            },
          }}>
          <BootstrapDialogTitle onClose={handleClose}>
            Application No : {props.application.applicationName}
          </BootstrapDialogTitle>
          <Typography
            style={{ marginLeft: '15px', alignItem: 'center' }}
            variant="h6"
            gutterBottom
            justifyContent="space-between"
            mb={1}>
            Meeting List
          </Typography>
          <Divider />
          <DialogContent
            sx={{
              overflow: 'hidden',
              display: 'flex',
              flexDirection: 'column',
              gap: '10px',
              height: 'calc(100% - 100px)', // Adjust height for DialogContent
            }}>
            <Grid container spacing={1} style={{ marginTop: '10px' }} gap={1}>
              <Box direction="column" alignItems="left" justifyContent="space-between" mb={1}>
                <TextField
                  select
                  fullWidth
                  style={{ marginTop: '7px', minHeight: '30px' }}
                  label="Select meeting to add application"
                  sx={{ marginTop: '5px', minWidth: '530px', height: '22px', fontSize: '0.875rem' }}
                  value={values.cutting_tree_meeting_id}
                  SelectProps={{
                    defaultOpen: true,
                    variant: 'outlined',
                    MenuProps: {
                      PaperProps: {
                        sx: {
                          maxWidth: '700px',
                          maxHeight: '200px',
                        },
                      },
                    },
                  }}
                  error={Boolean(touched.meetings && errors.meetings)}
                  helperText={touched.meetings && errors.meetings}
                  {...getFieldProps('cutting_tree_meeting_id')}
                  inputProps={{
                    classes: {
                      icon: classes.icon,
                    },
                  }}>
                  {meetingList?.map((option) => (
                    <MenuItem
                      key={option.id}
                      value={option.id}
                      style={{ minWidth: '500px', minHeight: '30px' }}
                      onClick={() => {
                        handleSelect(option);
                      }}>
                      <>
                        {option.date_of_meeting ? (
                          <Chip
                            label={`${moment(option.date_of_meeting).format('DD MMM YYYY')} - ${moment(
                              option.meeting_time,
                              'HH:mm:ss'
                            ).format('hh:mm A')}`}
                            key={option.id}
                            value={option.id}
                            variant="outlined"
                            className={classes.chipSelected}
                            style={{
                              fontWeight: '700',
                              borderRadius: '7px',
                              border: 'none',
                            }}
                            sx={{ mr: 1.5, mb: 1.5 }}
                          />
                        ) : (
                          <Box
                            sx={{
                              mr: 14,
                              mb: 1.5,
                            }}
                          />
                        )}
                        <Typography
                          id={option.id}
                          style={{
                            marginLeft: '15px',
                            alignItem: 'center',
                            display: 'flex',
                            flexWrap: 'wrap',
                            width: '300px',
                          }}
                          gutterBottom
                          justifyContent="space-between"
                          mb={1}>
                          {option.meeting_title}
                        </Typography>
                      </>
                    </MenuItem>
                  ))}
                </TextField>
              </Box>
            </Grid>
          </DialogContent>
          <DialogActions>
            {enableAdd ? (
              <Button type="click" onClick={handleClick} variant="contained">
                Add
              </Button>
            ) : null}
          </DialogActions>
        </Dialog>
      </form>
    </div>
  );
}
