import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import * as Yup from 'yup';
import { useFormik } from 'formik';
import {
  Button,
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Chip,
  TableCell,
  Box,
  Table,
  TableRow,
  TableContainer,
  TableBody,
  Pagination,
  Tooltip,
  CircularProgress,
  Drawer,
  MenuItem,
  TextField
} from '@mui/material';
import Select from 'react-select';
import CheckIcon from '@mui/icons-material/Check';
import IconButton from '@mui/material/IconButton';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { makeStyles } from '@material-ui/core/styles';

import { useDispatch, useSelector } from 'react-redux';
import { downloadExcel } from 'react-export-table-to-excel';

import TextWithTooltip from '../../components/Tooltip';

import MeetingListModal from '../../components/DialogBox/meeting/MeetingListModal';
import {
  GetTreeCuttingApplication,
  GetExcelTreeCuttingApplication,
  SearchTreeCuttingApplication,
  GetSiteInscepectorRellList,
} from '../../actions/TreeCuttingPermissionAction';

import Page from '../../components/Page';

import TreeCuttingListMenu from '../../sections/@dashboard/tree-cutting/TreeCuttingListMenu';
import TreeCuttingApplicationToolbar from '../../sections/@dashboard/tree-cutting/TreeCuttingApplicationToolbar';
import { UserListHead, UserListToolbar, UserFormListMenu } from '../../sections/@dashboard/user';
import StatusChip from '../../components/StatusChip';
import SideInscpectorList from '../../components/DialogBox/SiteInscpectorList';
import UseMoreMenuAction from '../../components/UseMoreMenuAction';
import ApplicationStatusChip from '../../components/ApplicationStatusChip';
import Iconify from '../../components/Iconify';
import { GetActiveTreeName } from '../../actions/TreeNameAction';


const TABLE_HEAD = [
  { id: 'srno', label: '#', alignRight: false },
  { id: 'application no', label: 'Application No', alignRight: false },
  { id: 'name', label: 'Applicant', alignRight: false },
  { id: 'contact', label: 'Contact No', alignRight: false },
  { id: 'ward', label: 'Ward', alignRight: false },
  // { id: 'property', label: 'Property No', alignRight: false },
  // { id: 'address', label: 'Address', alignRight: false },
  { id: 'Requested', label: 'Requested', alignRight: false },
  { id: 'Permitted', label: 'Permitted', alignRight: false },

  { id: 'Inspector', label: 'Inspector', alignRight: false },
  { id: 'InspectionAssignedat', label: 'Inspection Assigned at', alignRight: false },
  { id: 'inspectionstatus', label: 'Inspection Status', alignRight: false },

  { id: 'status', label: 'Status', alignRight: false },

  { id: 'action', label: 'Action', alignRight: false },
];

export default function TreeCuttingAllApplication(props) {
  const dispatch = useDispatch();
  const maxLength = 8;
  const [searchValue, setSearchValue] = useState(null);
  console.log('searchValue1998', searchValue);

  const [statusId, setStatusId] = useState('');
  const [showList, setShowList] = useState(false);
  const [open, setOpen] = useState(false);

  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [count, setCount] = useState(5);
  const [loader, setLoader] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  // const [lastPage, setLastPage] = useState(2);
  // const scrollContainerRef = useRef(null);
  // const loader = useRef(null);
  const [meetingParam, setMeetingParam] = useState({});
  const [application, setApplication] = useState({ applicationId: '', applicationName: '' });
  const [meetingId, setMeetingId] = useState('');
  const [treeNameInput, setTreeNameInput] = React.useState();
  const [filterId , setFilterId] = useState(2);
  const [wardID, setWardID] = React.useState('');
  const [siteInspectorId, setSiteInspectorId] = useState('');
  // To be removed
  const [meetingList, setMeetingList] = useState([
    {
      id: 1,
      meeting_title: 'Meeting related to cutting tree near power grid ',
      meeting_status: 'Scheduled',
      date_of_meeting: '21-08-2024',
      meeting_time: '',
    },
    {
      id: 2,
      meeting_title: 'Meeting related to historical tree cutting',
      meeting_status: 'Scheduled',
      date_of_meeting: '25-08-2024',
      meeting_time: '',
    },
    {
      id: 3,
      meeting_title: 'Meeting related to partially uprooted tree',
      meeting_status: 'Pending',
      date_of_meeting: '',
      meeting_time: '',
    },
    {
      id: 4,
      meeting_title: 'Meeting to cut trees for colonial establishments',
      meeting_status: 'Pending',
      date_of_meeting: '20-08-2024',
      meeting_time: '',
    },
    {
      id: 5,
      meeting_title: 'Meeting to cut trees for requesters personal comfort',
      meeting_status: 'Scheduled',
      date_of_meeting: '7-08-2024',
      meeting_time: '',
    },
  ]);

  const [newState, setNewState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const [siteInspectorList, setSiteInspectorList] = useState([]);

  const filterOptions = [
    { label: 'Ward wise applications', value: 1 },
    { label: 'Applicant wise applications', value: 2 },
    { label: 'Site Inspector Wise Application', value: 3 },
    { label: 'Tree Name Wise Application', value: 4 },
    { label: 'Date Range Wise Application', value: 5 },
    { label: 'Applicaions having heritage trees', value: 6 },
    { label: 'Applications having 100+ trees', value: 7 },
    { label: 'status wise applications', value: 8 },
    { label: 'Applications by inspection date range', value: 9 },
    { label: 'Applications by re-planation update date range', value: 10 },
    { label: 'Applications re scedule for inspections', value: 11 },
    { label: 'Application by meeting date', value: 12},
    { label: 'Application by Due re-planation update', value: 13 },
    { label: 'Applications completed replanted trees with 100%', value: 14 },
  ];

  // State for selected chip
  const [selectedChips, setSelectedChips] = useState([]);

  const handleChipClick = async (chip) => {
    // const newSelection = selectedChip?.value === chip.value ? null : chip;
    // setSelectedChip(newSelection);
    
    // if (newSelection) {
    //    // Call API with selected chip
    // }

    const isSelected = selectedChips.some(selected => selected.value === chip.value);
    const newSelectedChips = isSelected
      ? selectedChips.filter(selected => selected.value !== chip.value)
      : [...selectedChips, chip];

    setSelectedChips(newSelectedChips);
  };

  console.log('useLocation12', useLocation);

  useEffect(() => {
    dispatch(GetSiteInscepectorRellList()).then((response) => {
      console.log('response45365', response);
      setSiteInspectorList(response?.data?.data?.data);
    });
  }, []);

  let timer = null;
  const filterByName = (event) => {
    const { value } = event.currentTarget;
    clearTimeout(timer);
    // Wait for X ms and then process the request
    timer = setTimeout(() => {
      if (value) {
        setSearchValue(value);
      } else {
        setSearchValue('');
      }
    }, 1000);
  };

  const handleStatusChange = (e) => {
    setStatusId(e.target.value);
  };

  const useStyles = makeStyles({
    success: {
      backgroundColor: '#DDFAD1',
      color: '#507C59',
      border: '1px solid #507C59',
      fontSize: '12px',
      borderRadius: '5px',
      padding: '4px 10px',
      fontWeight: 600,
    },
    pending: {
      backgroundColor: '#efcbbd',
      color: '#CE5623',
      border: '1px solid #CE5623',
      fontSize: '12px',
      borderRadius: '5px',
      padding: '4px 10px',
      fontWeight: 600,
      pointerEvents: 'none',
    },
    icon: {
      fill: '#214C50',
    },
  });
  const classes = useStyles();

  const sampleData = [{}];

  const {
    treeCuttingAllApplications,
    excelTreeCuttingApplications,
    isReportDownloadable,
    pageInfo,
    searchTreeCuttingApplications,
    siteinspector,
    treeName,
    wards
  } = useSelector((state) => ({
    treeCuttingAllApplications: state.treeCutting.treeCuttingApplications,
    excelTreeCuttingApplications: state.treeCutting.excelTreeCuttingApplications,
    isReportDownloadable: state.treeCutting.isReportDownloadable,
    pageInfo: state.treeCutting.pageInfo,
    searchTreeCuttingApplications: state.treeCutting.searchTreeCuttingApplications,
    siteinspector: state.treeCutting.siteinspector,
    treeName: state.treeName.activeTreeName,
    wards: state.wards.activeWardsByCID,
  }));

  useEffect(()=>{
    dispatch(GetActiveTreeName(1));
  },[])

  useEffect(() => {
    setLoader(true);
    
    dispatch(GetTreeCuttingApplication(null, null, 1, rowsPerPage)).then((response) => {
      if (response.status === 200) {
        // setFilteredApplication(response.data.data);
        setLoader(false);
      }
    });
  }, [dispatch,siteinspector]);

  useEffect(() => {
    if (pageInfo) {
      setCount(pageInfo?.total);
      setCurrentPage(pageInfo?.current);
    }
  }, [pageInfo]);

  const secondRun = useRef(true);
  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    // setFilteredApplication(treeCuttingAllApplications);
    setLoader(false);
    setShowList(true);
  }, [dispatch, treeCuttingAllApplications]);

  const thirdRun = useRef(true);
  useEffect(() => {
    if (thirdRun.current) {
      thirdRun.current = false;
      return;
    }
    // setFilteredApplication(searchTreeCuttingApplications);
    setLoader(false);
    setShowList(true);
  }, [dispatch, searchTreeCuttingApplications]);

  useEffect(() => {
    if (searchValue ) {
      // const searchParam = {
      //   page,
      //   limit: rowsPerPage,
      //   search: searchValue,
      // };

      dispatch(SearchTreeCuttingApplication(1,rowsPerPage,searchValue)).then((response) => {
        if (response.status === 200) {
          setLoader(false);
        }
      });
    } else {
      dispatch(GetTreeCuttingApplication(null, null, 1, rowsPerPage));
    }
  }, [searchValue]);

  // useEffect(() => {
  //   setFilteredApplication(searchTreeCuttingApplications);
  // }, [dispatch, searchTreeCuttingApplications]);

  /* #region Export functionality */
  const [exportButtonPressed, setExportButtonPressed] = useState(false);

  useEffect(() => {
    if (exportButtonPressed) {
      DownloadReportExcel();
      setExportButtonPressed(false);
    }
  }, [isReportDownloadable, excelTreeCuttingApplications]);

  const header = [
    '#',
    'Application Number',
    'Name of Applicant',
    'Property Number',
    'Ward',
    'Mobile',
    'Email',
    'Location',
    'Trees to cut (Requested)',
    'Trees to cut (Granted)',
    'Total Fees Paid',
    'Total Trees to be planted',
    // 'Purpose',
  ];
  function DownloadReportExcel() {
    const value1 = [];
    excelTreeCuttingApplications?.map((option, index) => {
      const value2 = [index + 1];
      value2.push(option?.application_number);
      value2.push(option?.name);
      value2.push(option?.property_number ? option?.property_number : '-');
      value2.push(option?.ward?.name);
      value2.push(option?.mobile);
      value2.push(option?.email);
      // value2.push(option.location_type)
      value2.push(option?.location);
      value2.push(option?.total_trees);
      value2.push(option?.approved_cutting_trees_count);
      value2.push(option?.compensation_trees_amount ? option?.compensation_trees_amount : '-');
      value2.push(option?.total_trees_to_be_planted);
      // value2.push(option?.purpose_of_cutting);

      value1.push(value2);
      return null;
    });
    downloadExcel({
      fileName: `Tree Cutting`,
      sheet: 'Tree Cutting',
      tablePayload: {
        header,
        // accept two different data structures
        body: value1,
      },
    });
  }

  const exportReports = () => {
    dispatch(GetExcelTreeCuttingApplication(new Date(), new Date(), searchValue));
    setExportButtonPressed(true);
  };
  /* #endRegion */

  const handleChangePage = (event, newPage) => {
    
    setPage(newPage);

    setLoader(true);
    // setShowList(false);
    if (searchValue) {
      dispatch(SearchTreeCuttingApplication(newPage, rowsPerPage, searchValue));
    } else {
      dispatch(GetTreeCuttingApplication(null, null, newPage, rowsPerPage));
    }
  };

  const handleEdit = (id) => {
    console.log('Edit Application Data', id);
    // dispatch();
  };

  const handleAddToMeeting = (data, value) => {
    console.log('MEETING LIST', data);
    console.log('Application Id', value.id);
    if (data) {
      setMeetingList(data);
      setApplication({ applicationId: value.id, applicationName: value.application_number });
      setOpen(true);
    }
  };

  const handleCloseModal = () => {
    setOpen(!open);
    dispatch(GetTreeCuttingApplication(null, null, 1, rowsPerPage)).then((response) => {
      if (response.status === 200) {
        // setFilteredApplication(response.data.data);
        setLoader(false);
      }
    });
  };

  const handleClick = (meetingId, application) => {
    if (application && application?.applicationId) {
      console.log('APPLICATIONID', application?.applicationId);
      console.log('MeetingId', meetingId);
    }
  };
  const [openSiteIncepectorList, setOpenSiteIncepectorList] = useState(false);

  const handleAddSiteInscpector = (data, value) => {
    if (value) {
      setApplication({ applicationId: value.id, applicationName: value.application_number });
    }
    setOpenSiteIncepectorList(true);
  };

  const handleClosesiteList = () => {
    setOpenSiteIncepectorList(false);
    setLoader(true);
    dispatch(GetTreeCuttingApplication(null, null, 1, rowsPerPage)).then((response) => {
      if (response.status === 200) {
        // setFilteredApplication(response.data.data);
        setLoader(false);
      }
    });
  };

  const toggleDrawer = (anchor, open) => (event) => {
    // setshowImportStatus(false);
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setNewState({ ...newState, [anchor]: open });
  };

  const handleTreeNameSelect = (e) => {
    setTreeNameInput(e);
    console.log('data', e);
  };

  const handleFilterChange = (event) => {
    setFilterId(event.target.value);
    if(event.target.value===3){
      dispatch(GetSiteInscepectorRellList()).then((response) => {
        if (response.status === 200) {
          setSiteInspectorList(response.data.data);
        }
      });
    }
  };

  const handleWardChange = (event) => {
    setWardID(event.target.value);
  };

  const handleSelect = (value) => {
    setSiteInspectorId(value.id)
  };

  const FilterSchema = Yup.object().shape({
    filter: Yup.string().required('Please select filter'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      filter : ''
    },
    validationSchema: FilterSchema,
    onSubmit: (value) => {
      
    },
  });

  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps } = formik;

  return (
    <Page title="Tree Cutting Application">
      <Container>
        {/* modules  */}
        {open ? (
          <MeetingListModal
            isOpen={open}
            handleClick={handleClick}
            handleClose={handleCloseModal}
            meetingList={meetingList}
            application={application}
          />
        ) : null}
        {openSiteIncepectorList ? (
          <SideInscpectorList
            isOpen={openSiteIncepectorList}
            handleClick={handleClick}
            handleClose={handleClosesiteList}
            siteInspectorList={siteInspectorList}
            application={application}
          />
        ) : null}

        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={1}>
          <Typography variant="h4" gutterBottom>
            Tree Cutting Application
            <Typography variant="h6" style={{ fontWeight: 400 }}>
              It is showing list of all application its details
            </Typography>
          </Typography>
          <Box display="flex" flexDirection="row-reverse" gap={2}>
          {/* <Button
              onClick={toggleDrawer('right', true)}
              variant="contained"
              to="#"
              // sx={{ fontSize: '10px', fontWeight: '700', height: '27px' }}
              startIcon={<Iconify icon="eva:funnel-fill" />}>
              Filters
            </Button> */}
          <Button variant="contained" component={RouterLink} to="/dashboard/create-tree-cutting-permission">
            Add New Application
          </Button>
          </Box>
        </Stack>

        <Stack direction="row"  mb={1}>
          <Box sx={{ height: '100' }}>
            <Drawer
              sx={{
                '& .MuiDrawer-paper': {
                  width: '350px',
                  maxWidth: '100%',
                  justifyContent: 'start',
                  alignItems: 'center',
                  paddingTop:'2%'
                },
              }}
              anchor={'right'}
              open={newState.right}
              onClose={toggleDrawer('right', false)}>
              <div >
                <Grid container spacing={1} style={{ width: '90%', marginLeft: '5%', marginRight: '5%' }}> 
                  <Stack direction="column" spacing={2} style={{width:'100%'}}>
                    <Grid item xs={12}>
                    <TextField
                    fullWidth
                      select
                      id="filter"
                      name="filter"
                      label="Filter"
                      displayEmpty
                      value={filterId}
                      style={{ width: '100%', marginTop: 5 }}
                      size="small"
                      onChange={(e) => {
                        handleFilterChange(e);
                        formik.handleChange(e);
                      }}
                      // onChange={handleWardChange}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Filter*</em>
                      </MenuItem>
                      {filterOptions?.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                              {option.label}
                            </MenuItem>
                          ))
                       }
                    </TextField>
                  </Grid>
                  {filterId===2 &&
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      id="wardForm"
                      label="Applicant"
                      displayEmpty
                      value={wardID}
                      style={{ width: '100%', marginTop: 5 }}
                      size="small"
                      onChange={(e) => {
                        handleWardChange(e);
                      }}
                      // onChange={handleWardChange}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Applicant*</em>
                      </MenuItem>
                      {wards?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))
                        }
                    </TextField>
                  </Grid>}
                  {filterId===1 &&
                  <Grid item xs={12}>
                    <TextField
                      select
                      fullWidth
                      id="wardForm"
                      label="Ward"
                      displayEmpty
                      value={wardID}
                      style={{ width: '100%', marginTop: 5 }}
                      size="small"
                      onChange={(e) => {
                        handleWardChange(e);
                      }}
                      // onChange={handleWardChange}
                      inputProps={{
                        classes: {
                          icon: classes.icon,
                        },
                      }}>
                      <MenuItem disabled value="">
                        <em>Select Ward*</em>
                      </MenuItem>
                      {wards?.map((option) => (
                            <MenuItem key={option.id} value={option.id}>
                              {option.name}
                            </MenuItem>
                          ))
                        }
                    </TextField>
                  </Grid>
                  }
                   {filterId===3 &&
                  <Grid item xs={12}>
                  <TextField
                select
                fullWidth
                label="Select site Inspector"
                value={siteInspectorId}
                SelectProps={{
                  variant: 'outlined',
                  MenuProps: {
                    PaperProps: {
                      className: classes.menuPaper,
                    },
                  },
                }}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}>
                {siteInspectorList?.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    style={{ minWidth: '500', minHeight: '30px' }}
                    onClick={() => {
                      handleSelect(option);
                    }}>
                    {option.full_name}
                  </MenuItem>
                ))}
              </TextField>
                  </Grid>}
                  {(filterId===5 || filterId===9 || filterId===10 || filterId===12 || filterId===13) &&
                  <div>
                  <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="fromDate"
                    type="date"
                    label="Start Date"
                    margin="normal"
                    name="fromDateForm"
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
      
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    id="toDate"
                    type="date"
                    label="End Date"
                    margin="normal"
                    name="toDateForm"
                    style={{ width: '100%', marginTop: 5 }}
                    size="small"
      
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                </div>}
                {filterId===4 &&
                     <Grid item xs={12}>
                    <Select
                      id="treeNameFrom"
                      placeholder="Select Tree Name"
                      label="Tree Name"
                      value={treeNameInput}
                      className="react-select-container"
                      componentsProps={{
                        listbox: {
                          sx: { backgroundColor: '#000' },
                        },
                      }}
                      style={{ borderColor: 'red' }}
                      clearable={false}
                      options={treeName?.map((item) => {
                        return { value: item.id, label: item.name };
                      })}
                      // eslint-disable-next-line react/jsx-no-bind
                      onChange={handleTreeNameSelect}
                    />
                  </Grid>}
                  </Stack>
                  <Button
                    onClick={()=>console.log("hii")}
                    variant="contained"
                    style={{ width: '60%', marginLeft: '20%', marginRight: '20%', marginTop: 10 }}>
                    Apply
                  </Button>
                </Grid>
              </div>
            </Drawer>
          </Box>
        </Stack>


        <Card>
          <TreeCuttingApplicationToolbar
            onFilterName={filterByName}
            statusId={statusId}
            handleStatusChange={handleStatusChange}
            // exportReports={exportReports}
            handleexportReports={exportReports}
          />
          <TableContainer sx={{ minWidth: 700, overflow: 'auto' }}>
            <Table size="small" aria-label="a dense table" sx={{ overflow: 'hidden' }}>
              <UserListHead headLabel={TABLE_HEAD} />
              {loader ? (
                <TableBody>
                  <TableRow>
                    <TableCell colSpan={9} style={{ whiteSpace: 'nowrap', width: '2000px' }}>
                      {loader && (
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                          <CircularProgress />
                        </div>
                      )}
                    </TableCell>
                  </TableRow>
                </TableBody>
              ) : (
                <TableBody>
                  {treeCuttingAllApplications?.length > 0 ? (
                    treeCuttingAllApplications?.map((value, index) => {
                      console.log('Cuttinggg', value?.active_inspection?.site_inspector?.full_name);
                      return (
                        <TableRow hover>
                          <TableCell align="left">
                            <b>{pageInfo.per_page * (pageInfo.current_page - 1) + index + 1}</b>
                          </TableCell>
                          <TableCell>{value.application_number}</TableCell>
                          <TableCell align="left">
                            <TextWithTooltip text={value.name} maxLength={maxLength} />
                          </TableCell>
                          <TableCell align="left">{value.mobile}</TableCell>
                          <TableCell align="center">{value.ward.name}</TableCell>
                          {/* <TableCell align="left">{value.property_number}</TableCell> */}
                          {/* <TableCell align="left">
                            <TextWithTooltip text={value.location} maxLength={maxLength} />
                          </TableCell> */}
                          <TableCell align="left">{value.total_trees}</TableCell>

                          <TableCell align="left">{value?.total_trees_permitted}</TableCell>

                          {/* site inspector name */}
                          <TableCell align="left">{value?.active_inspection?.site_inspector?.full_name}</TableCell>
                          {/* site inspector assign date */}

                          <TableCell align="left">{value?.active_inspection?.assigned_at}</TableCell>

                          <TableCell align="left">{value?.inspection_status}</TableCell>

                          <TableCell align="left">
                            <ApplicationStatusChip status={value?.application_status?.application_status} />
                          </TableCell>

                          <TableCell align="right">
                            <UseMoreMenuAction
                              status={
                                value.application_status?.application_status
                                  ? value.application_status?.application_status
                                  : '---'
                              }
                              statusId={value?.application_status?.id}
                              IsApplication
                              handleAddToMeeting={() => handleAddToMeeting(meetingList, value)}
                              handleAddSiteInscpector={() => handleAddSiteInscpector(meetingList, value)}
                              id={value?.id}
                              // status={info.meeting_status}
                              // handleDelete={() => handleOpenDelete(info?.id)}
                              handleEdit={() => handleEdit(value?.id)}
                              editUrl="/dashboard/update-tree-cutting-permission"
                              data={value}
                              ProclamationId={value?.newspaper_proclamations[0]?.id}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })
                  ) : (
                    <TableRow>
                      <TableCell colSpan={9}>
                        <div style={{ display: 'flex', justifyContent: 'center', padding: '50px' }}>
                          <Typography>No data found !</Typography>
                        </div>
                      </TableCell>
                    </TableRow>
                  )}
                </TableBody>
              )}
            </Table>
          </TableContainer>
          <Box>
            <Pagination
              count={pageInfo.last_page }
              page={page}
              variant="outlined"
              shape="rounded"
              onChange={handleChangePage}
              sx={{ justifyContent: 'right', display: 'flex', mt: 3, mb: 3 }}
            />
          </Box>
        </Card>
      </Container>
    </Page>
  );
}
