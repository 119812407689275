import * as React from 'react';
import { useEffect, useState, useRef } from 'react';
import moment from 'moment';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { useFormik } from 'formik';
import { useDispatch } from 'react-redux';
import {
  Card,
  Container,
  Grid,
  Stack,
  Typography,
  Chip,
  TableCell,
  Box,
  CardContent,
  MenuItem,
  Select,
  Tooltip,
  TextField,
} from '@mui/material';
import { makeStyles } from '@material-ui/core/styles';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import { GetSiteInscepectorRellList, AddSiteInspector } from '../../actions/TreeCuttingPermissionAction';
import { AssignReplantation } from '../../actions/UpcomingReplantationAction';

const BootstrapDialogTitle = (props) => {
  const { children, onClose, ...other } = props;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[800],
          }}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
};

BootstrapDialogTitle.propTypes = {
  children: PropTypes.node,
  onClose: PropTypes.func.isRequired
};

export default function SiteInspectorList(props) {
  const dispatch = useDispatch();
  const { isOpen } = props;
  const [enableAdd, setEnableAdd] = useState(false);
  const [params, setParams] = useState(null);
  const [submit, setSubmit] = useState(false);
  const [inspectorList, setInspectorList] = useState([]);

  const firstRun = useRef(true);
  useEffect(() => {
    if (firstRun.current) {
      firstRun.current = false;
    }
  }, []);

  useEffect(() => {
    dispatch(GetSiteInscepectorRellList()).then((response) => {
      if (response.status === 200) {
        setInspectorList(response.data.data);
      }
    });
  }, [dispatch]);

  const secondRun = React.useRef(true);

  useEffect(() => {
    if (secondRun.current) {
      secondRun.current = false;
      return;
    }
    if (submit) {
      if(props.isReplantationSiteInspector){
        const reqObj = {
          user_id:params.user_id,
          replantation_update_id : props.replantationUpdateId

        }
        dispatch(AssignReplantation(reqObj)).then((response) => {
          console.log("RESPONSE",response);
          if (response.status === 200) {
            // setFilteredApplication(response.data.data);
            props.handleClose();
          }
        });;
      }
      else{
        dispatch(AddSiteInspector(params));
        props.handleClose();
      }
      
    }
  }, [params, submit]);
 

  const handleSelect = (value) => {
    setParams({
      tree_cutting_application_id: props.application.applicationId,
      inspection_type: 'new_application',
      user_id: value.id,
    });
    setSubmit(false);
    setEnableAdd(true);
  };

  const handleClick = (event) => {
    setEnableAdd(false);
    setSubmit(true);
  };

  const handleClose = () => {
    props.handleClose(false);
  };

  const handleTrueClose = () => {
    props.handleClose(true);
  };

  const useStyles = makeStyles({
    icon: {
      fill: '#4CAF50',
    },
    chipSelected: {
      backgroundColor: '#4CAF50',
      color: '#fff',
    },
    chip: {
      backgroundColor: '#3f51b5',
      color: '#000',
    },
    dialogContent: {
      overflow: 'hidden', // Hide vertical scroll
    },
    menuPaper: {
      maxHeight: '400px',
      overflowY: 'auto', // Enable scroll in dropdown
    },
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      cutting_tree_meeting_id: '',
      cutting_tree_application_id: '',
    },
    onSubmit: (value) => {
      console.log('On Submit :', value);
    },
  });

  const classes = useStyles();
  const { errors, touched, values, isSubmitting, handleSubmit, getFieldProps, resetForm, setFieldValue } = formik;

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        PaperProps={{
          style: {
            width: '600px',
            height: '450px',
          },
        }}>
        <BootstrapDialogTitle onClose={handleClose}>
          Application No : {props.application.applicationName}
        </BootstrapDialogTitle>
        <Typography
          style={{ marginLeft: '15px', alignItems: 'center' }}
          variant="h6"
          gutterBottom
          justifyContent="space-between"
          mb={1}>
          Site Inspector List
        </Typography>
        <Divider />
        <DialogContent className={classes.dialogContent}>
          <Grid
            container
            spacing={1}
            style={{ marginTop: '10px', height: 500, p: 1, margin: 'auto', maxWidth: 700, flexGrow: 1 }}
            gap={1}>
            <Box direction="column" alignItems="left" justifyContent="space-between" mb={1}>
              <TextField
                select
                fullWidth
                style={{ marginTop: '7px', minHeight: '30px' }}
                label="Select site Inspector"
                sx={{ marginTop: '5px', minWidth: '530px', height: '22px', fontSize: '0.875rem' }}
                value={values.council_id}
                SelectProps={{
                  defaultOpen: true,
                  variant: 'outlined',
                  MenuProps: {
                    PaperProps: {
                      className: classes.menuPaper,
                    },
                  },
                }}
                error={Boolean(touched.meetings && errors.meetings)}
                helperText={touched.meetings && errors.meetings}
                {...getFieldProps('cutting_tree_meeting_id')}
                inputProps={{
                  classes: {
                    icon: classes.icon,
                  },
                }}>
                {inspectorList?.map((option) => (
                  <MenuItem
                    key={option.id}
                    value={option.id}
                    style={{ minWidth: '500', minHeight: '30px' }}
                    onClick={() => {
                      handleSelect(option);
                    }}>
                    {option.full_name}
                  </MenuItem>
                ))}
              </TextField>
            </Box>
          </Grid>
        </DialogContent>
        <DialogActions>
          {enableAdd ? (
            <Button type="click" onClick={handleClick} variant="contained">
              Add
            </Button>
          ) : null}
        </DialogActions>
      </Dialog>
    </div>
  );
}
