import React, { useState, useEffect } from 'react';
import {
  CardActionArea,
  CardMedia,
  Card,
  CardContent,
  Box,
  Button,
  Divider,
  Grid,
  Menu,
  MenuItem,
  Tab,
  Typography,
  CircularProgress,
  IconButton,
  Link,
  Chip,
} from '@mui/material';
import { green, orange, red, gray } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';

import { useDispatch, useSelector } from 'react-redux';
import FilePresentIcon from '@mui/icons-material/FilePresent';

import pdfImg from '../../../Assets/pdf.png';

const ViewMeetingApplicationDetailsApplications = ({ applicationDetailsData, showLoader, showTitleData }) => {
  console.log('applicationDetailsData1998', applicationDetailsData);

  return (
    <Box>
      {showLoader ? (
        <Card sx={{ margin: '15px', padding: '20px' }}>
          <Grid container>
            <Grid item lg={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', alignItem: 'center' }}>
              <CircularProgress />
            </Grid>
          </Grid>
        </Card>
      ) : (
        <Box>
          <Card sx={{ margin: '15px', padding: '20px' }}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: '700' }}>Applicant Name</Typography>
                <Typography>{applicationDetailsData?.name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: '700' }}>Contact Number</Typography>
                <Typography>{applicationDetailsData?.mobile}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography sx={{ fontWeight: '700' }}>Date</Typography>
                <Typography>{applicationDetailsData?.created_at}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: '700' }}>Applied By</Typography>
                <Typography>{applicationDetailsData?.applied_by}</Typography>
              </Grid>
              {/* --- */}

              <Grid item xs={6}>
                <Typography sx={{ fontWeight: '700' }}>Ward No. </Typography>
                <Typography>{applicationDetailsData?.ward?.name}</Typography>
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: '700' }}>Property No. </Typography>
                <Typography>{applicationDetailsData?.property_number}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography sx={{ fontWeight: '700' }}>Address</Typography>
                <Typography>{applicationDetailsData?.location}</Typography>
              </Grid>

              {/* --- */}

              <Grid item xs={6}>
                <Typography sx={{ fontWeight: '700' }}>Total Trees Requested</Typography>
                <Typography>{applicationDetailsData?.total_trees}</Typography>
              </Grid>

              <Grid item xs={6}>
                <Typography sx={{ fontWeight: '700' }}>Have 100 plus trees ?</Typography>
                {applicationDetailsData?.have_100_plus_trees === 1 ? (
                  <Chip
                    label="Yes"
                    size="small"
                    style={{
                      marginX: '10px',
                      fontWeight: '600',
                      padding: '1px 10px',
                      backgroundColor: red[600],
                      color: 'white',
                      '&:hover': {
                        backgroundColor: red[800],
                      },
                    }}
                  />
                ) : (
                  <Chip
                    label="No"
                    size="small"
                    style={{
                      marginX: '10px',
                      fontWeight: '600',
                      padding: '1px 10px',
                      backgroundColor: green[600],
                      color: 'white',
                      '&:hover': {
                        backgroundColor: green[800],
                      },
                    }}
                  />
                )}
              </Grid>

              <Grid item xs={6}>
                <Typography sx={{ fontWeight: '700' }}>Have heritage trees ?</Typography>
                {applicationDetailsData?.have_heritage_trees === 1 ? (
                  <Chip
                    label="Yes"
                    size="small"
                    style={{
                      marginLeft: '10px',
                      fontWeight: '600',
                      padding: '1px 10px',
                      backgroundColor: red[600],
                      color: 'white',
                      '&:hover': {
                        backgroundColor: red[800],
                      },
                    }}
                  />
                ) : (
                  <Chip
                    label="No"
                    size="small"
                    style={{
                      marginLeft: '10px',
                      fontWeight: '400',
                      padding: '1px 10px',
                      backgroundColor: green[600],
                      color: 'white',
                      '&:hover': {
                        backgroundColor: green[800],
                      },
                    }}
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                <Typography sx={{ fontWeight: '700' }}>Deposit Amount</Typography>

                <Typography>
                  <span sx={{ color: '#214c50' }}>{'\u20B9'}</span> {applicationDetailsData?.deposit_amount}
                  {!applicationDetailsData.processing === 'Paid' ? (
                    <Chip
                      label="Unpaid"
                      size="small"
                      style={{
                        marginLeft: '10px',
                        fontWeight: '600',
                        padding: '1px 10px',
                        backgroundColor: red[600],
                        color: 'white',
                        '&:hover': {
                          backgroundColor: red[800],
                        },
                      }}
                    />
                  ) : (
                    <Chip
                      label="Paid"
                      size="small"
                      style={{
                        marginLeft: '10px',
                        fontWeight: '400',
                        padding: '1px 10px',
                        backgroundColor: green[600],
                        color: 'white',
                        '&:hover': {
                          backgroundColor: green[800],
                        },
                      }}
                    />
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Card>
        </Box>
      )}
    </Box>
  );
};

export default ViewMeetingApplicationDetailsApplications;
