import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { useFormik } from 'formik';
import { Link as RouterLink, useNavigate ,useLocation} from 'react-router-dom';
import * as Yup from 'yup';
import { Link, Button, Grid, TextField, Typography, Stack, Card, IconButton, CardMedia } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import DescriptionIcon from '@mui/icons-material/Description';
import { uploadProclamationRecipt } from '../../actions/ProclamationActions';

const AddRecipt = () => {
  const dispatch = useDispatch();
  const location = useLocation();
    const navigate = useNavigate();

  console.log('location67', location.state);
  const id = location.state.data.id;
  const proclamationId = location.state.ProclamationId;

  console.log('id', id);
  const [selectedImage, setSelectedImage] = useState(null);
  const [imageError, setImageError] = useState(null);
  console.log('selectedImage', selectedImage);
  const allowedFileTypes = ['image/jpeg', 'image/jpg', 'image/png', 'image/gif', 'image/svg+xml', 'application/pdf'];
  const allowedExtensions = ['jpeg', 'jpg', 'png', 'gif', 'svg', 'pdf'];

  const formik = useFormik({
    initialValues: {
      publishingCharges: '',
      files: '',
    },
    validationSchema: Yup.object({
      files: Yup.string().required('Proclamation Receipt is required'),
      // publishingCharges: Yup.number()
      //   .typeError('Publishing Charges must be a number')
      //   .required('Publishing Charges is required')
      //   .positive('Publishing Charges must be a positive number')
      //   .integer('Publishing Charges must be an integer'),
    }),
    onSubmit: (values) => {
      console.log('Form Values:', values);
      const formData = new FormData();
      formData.append('fees', location?.state?.data?.newspaper_proclamations[0]?.fees);
      formData.append(`fees_receipt`, values.files);

      dispatch(uploadProclamationRecipt(formData, proclamationId)).then((response)=>{
        if(response.status === 201){
          navigate("/dashboard/tree-cutting-application")
        }
      });
    },
  });

  const handleReciptAdd = (event) => {
    const recipt = event.target.files;
    const maxFileSize = 14 * 1024 * 1024;
    const isValidFiles = allowedFileTypes.includes(recipt[0].type);
    const isValidSizes = recipt[0].size <= maxFileSize;

    if (!isValidFiles) {
      setImageError('File format not supported');
      formik.setFieldError('files', 'File format not supported');
    }
    if (!isValidSizes) {
      formik.setFieldError('files', 'File size exceeds 14 MB');
      return;
    }
    if (isValidFiles && isValidSizes) {
      setImageError();
      formik.setFieldError('uploadedRecipt', '');
      setSelectedImage(event.target.files[0]);
      formik.setFieldValue('files', event.target.files[0]);
    }
  };

  const handleRemoveImage = () => {
    setSelectedImage(null);
    formik.setFieldValue('uploadedImage', null);
  };

  return (
    <div>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" style={{ marginBottom: '-20px', marginLeft: '10px' }}>
          {'Add Proclamation Receipt'}
          <Typography variant="h6" style={{ fontWeight: '400' }}>
            Please Provide the details.
          </Typography>
        </Typography>
      </Stack>
      <Card variant="outlined" style={{ padding: '20px', margin: 10, boxShadow: 'none' }}>
        <form onSubmit={formik.handleSubmit}>
          <Grid container rowSpacing={2} columnSpacing={2}>


          <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                id="applicant Number"
                name="applicantNumber"
                label="Applicant Number"
                variant="outlined"
                value={location?.state?.data?.application_number}
                onChange={formik.handleChange}
                // error={formik.touched.publishingCharges && Boolean(formik.errors.publishingCharges)}
                // helperText={formik.touched.publishingCharges && formik.errors.publishingCharges}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                id="applicantName"
                name="applicantName"
                label="Applicant Name"
                variant="outlined"
                value={location?.state?.data?.name}
                onChange={formik.handleChange}
                // error={formik.touched.publishingCharges && Boolean(formik.errors.publishingCharges)}
                // helperText={formik.touched.publishingCharges && formik.errors.publishingCharges}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                id="mobileNumber"
                name="mobileNumber"
                label="Mobile Number"
                variant="outlined"
                value={location?.state?.data?.mobile}
                onChange={formik.handleChange}
                // error={formik.touched.publishingCharges && Boolean(formik.errors.publishingCharges)}
                // helperText={formik.touched.publishingCharges && formik.errors.publishingCharges}
              />
            </Grid>
            {/* Publishing Charges */}
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled
                id="publishingCharges"
                name="publishingCharges"
                label="Publishing Charges (₹)*"
                variant="outlined"
                value={location?.state?.data?.newspaper_proclamations[0]?.fees}
                onChange={formik.handleChange}
                // error={formik.touched.publishingCharges && Boolean(formik.errors.publishingCharges)}
                // helperText={formik.touched.publishingCharges && formik.errors.publishingCharges}
              />
            </Grid>

            {/* Upload Image */}
            <Grid item xs={6}>
              {selectedImage ? (
                <>
                  
                  <div
                    style={{
                      position: 'relative',
                      width: '100px',
                    }}>
                    <div
                      style={{
                        width: '100px',
                        height: '70px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: '#f0f0f0',
                        borderRadius: '10px',
                      }}>
                      <DescriptionIcon style={{ fontSize: 36 }} />
                      <IconButton
                        style={{
                          position: 'absolute',
                          top: '5px',
                          right: '5px',
                          background: 'red',
                          width: '20px',
                          height: '20px',
                        }}
                        onClick={() => handleRemoveImage()}>
                        <CloseIcon style={{ color: 'white', fontSize: 16 }} />
                      </IconButton>
                    </div>
                  </div>
                  <Typography>{selectedImage.name} </Typography>
                </>
              ) : (
                <>
                  <TextField
                    fullWidth
                    id="files-input"
                    name="files"
                    type="file"
                    style={{ display: 'none' }}
                    onChange={(e) => handleReciptAdd(e)}
                    error={formik.touched.files && Boolean(formik.errors.files)}
                    helperText={formik.touched.files && formik.errors.files}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    aria-describedby="file-upload-label"
                  />

                  <Button
                    onClick={() => {
                      document.getElementById('files-input').click();
                    }}
                    sx={{
                      fontWeight: 500,
                      width: '50%',
                      height: '50px',
                      padding: '10px',
                      boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.1)',
                      '&:hover': {
                        boxShadow: '0px 3px 5px rgba(0, 0, 0, 0.2)',
                      },
                    }}>
                    Upload Receipt
                  </Button>
                  <Typography sx={{ mt: '5px', color: '#FF4842', fontSize: ' 0.75rem' }}>
                    {formik.errors.files || imageError}
                  </Typography>
                </>
              )}
            </Grid>

         
            <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Button
                size="small"
                variant="contained"
                style={{
                  fontSize: 13,
                  padding: '10px',
                }}
                type="submit">
                Upload Receipt
              </Button>
            </Grid>
          </Grid>
        </form>
      </Card>
    </div>
  );
};

export default AddRecipt;
