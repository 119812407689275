export const LOGIN = 'login';
export const LOG_OUT = 'logout';
export const RESET_STATE = 'reset_state';

export const SET_ALERT = 'set-new-alert';
export const REMOVE_ALERT = 'remove-alert';
export const REMOVE_SPECIFIC_ALERT = 'remove-specific-alert';
export const DELETE_ALL_ALERT = 'delete-all-alert';

export const REMINDER_ALERT = 'reminder-alert';

export const GET_STATE = 'get-state';
export const GET_ACTIVE_STATE = 'get-active-state';
export const EDIT_STATE = 'edit-state';
export const ADD_STATE = 'add-state';
export const DELETE_STATE = 'delete-state';

export const GET_DASHBORAD_BY_COUNCIL_ID = 'get-dashboard-by-council-id';
export const GET_UNSYNCHEDUSERS_BY_COUNCIL_ID = 'get-unsynchedusers-by-council-id';
export const GET_WORK_LOGGED_BY_COUNCIL_ID = 'get-work-logged-by-council-id';
export const GET_TEAMS_BY_COUNCIL_ID = 'get-teams-by-council-id';
export const GET_TEAM_DETAIL_BY_COUNCIL_TEAM_ID = 'get-teamdetail-by-council-team-id';

export const GET_DISTRICTS = 'get-districts';
export const GET_ACTIVE_DISTRICTS = 'get-active-districts';
export const EDIT_DISTRICTS = 'edit-districts';
export const ADD_DISTRICTS = 'add-districts';
export const DELETE_DISTRICTS = 'delete-districts';
export const GET_DISTRICTS_BY_STATE_ID = 'get-districts-by-state-id';

export const GET_TALUKAS = 'get-talukas';
export const GET_ACTIVE_TALUKAS = 'get-active-talukas';
export const EDIT_TALUKAS = 'edit-talukas';
export const ADD_TALUKAS = 'add-talukas';
export const DELETE_TALUKAS = 'delete-talukas';

export const GET_DESIGNATIONS = 'get-designations';
export const GET_ACTIVE_DESIGNATIONS = 'get-active-designations';
export const EDIT_DESIGNATIONS = 'edit-designations';
export const ADD_DESIGNATIONS = 'add-designations';
export const DELETE_DESIGNATIONS = 'delete-designations';

export const GET_TREE_TYPE = 'get-tree-type';
export const GET_ACTIVE_TREE_TYPE = 'get-active-tree-type';
export const EDIT_TREE_TYPE = 'edit-tree-type';
export const ADD_TREE_TYPE = 'add-tree-type';
export const DELETE_TREE_TYPE = 'delete-tree-type';

export const GET_TREE_NAME = 'get-tree-name';
export const GET_ACTIVE_TREE_NAME = 'get-active-tree-name';
export const EDIT_TREE_NAME = 'edit-tree-name';
export const ADD_TREE_NAME = 'add-tree-name';
export const DELETE_TREE_NAME = 'delete-tree-name';

export const GET_TREE_CONDITIONS = 'get-tree-conditions';
export const GET_ACTIVE_TREE_CONDITIONS = 'get-active-tree-conditions';
export const EDIT_TREE_CONDITIONS = 'edit-tree-conditions';
export const ADD_TREE_CONDITIONS = 'add-tree-conditions';
export const DELETE_TREE_CONDITIONS = 'delete-tree-conditions';

export const GET_ZONES = 'get-zones';
export const GET_ACTIVE_ZONES = 'get-active-zones';
export const GET_ACTIVE_ZONES_BY_COUNCILID = 'get-active-zones-by-councilid';
export const EDIT_ZONES = 'edit-zones';
export const ADD_ZONES = 'add-zones';
export const DELETE_ZONES = 'delete-zones';

export const GET_PROPERTY_TYPES = 'get-property-types';
export const GET_ACTIVE_PROPERTY_TYPES = 'get-active-property-types';
export const EDIT_PROPERTY_TYPES = 'edit-property-types';
export const ADD_PROPERTY_TYPES = 'add-property-types';
export const DELETE_PROPERTY_TYPES = 'delete-property-types';

export const GET_LOCATION_TYPES = 'get-location-types';
export const GET_ACTIVE_LOCATION_TYPES = 'get-active-location-types';
export const EDIT_LOCATION_TYPES = 'edit-location-types';
export const ADD_LOCATION_TYPES = 'add-location-types';
export const DELETE_LOCATION_TYPES = 'delete-location-types';

export const GET_TREEDENSITY = 'get-treedensity';
export const EDIT_TREEDENSITY = 'edit-treedensity';
export const ADD_TREEDENSITY = 'add-treedensity';
export const DELETE_TREEDENSITY = 'delete-treedensity';
// export const GET_TREEDENSITY_BY_STATE_ID = "get-districts-by-state-id";

export const GET_QCREMARKS = 'get-qcremarks';
export const EDIT_QCREMARKS = 'edit-qcremarks';
export const ADD_QCREMARKS = 'add-qcremarks';
export const DELETE_QCREMARKS = 'delete-qcremarks';
// export const GET_DISTRICTS_BY_STATE_ID = "get-districts-by-state-id";

export const GET_WARDS = 'get-wards';
export const GET_ACTIVE_WARDS = 'get-active-wards';
export const GET_ACTIVE_WARDS_BY_COUNCILID = 'get-active-wards-by-councilid';
export const EDIT_WARDS = 'edit-wards';
export const ADD_WARDS = 'add-wards';
export const DELETE_WARDS = 'delete-wards';

export const GET_COUNCIL = 'get-council';
export const GET_ACTIVE_COUNCIL = 'get-active-council';
export const GET_COUNCIL_BY_ID = 'get-council-by-id';
export const EDIT_COUNCIL = 'edit-council';
export const ADD_COUNCIL = 'add-council';
export const DELETE_COUNCIL = 'delete-council';
export const GET_COUNCIL_META_DETAILS = 'get-council-meta-details';
export const ADD_COUNCIL_META_DETAILS = 'add-council-meta-details';

export const GET_TEAM = 'get-team';
export const GET_ACTIVE_TEAM = 'get-active-team';
export const EDIT_TEAM = 'edit-team';
export const ADD_TEAM = 'add-team';
export const DELETE_TEAM = 'delete-team';

export const GET_CZW_BY_TEAM = 'get-czw-by-team';
export const ASSIGN_CZW_TO_TEAM = 'assign-czw-to-team';
export const DELETE_ASSIGNED_CZW = 'delete-assigned-czw';

export const GET_USERS_BY_TEAM = 'get-user-by-team';
export const ASSIGN_USERS_TO_TEAM = 'assign-user-to-team';
export const DELETE_ASSIGNED_USER = 'delete-assigned-user';

export const GET_ROLE = 'get-role';
export const GET_ACTVE_ROLE = 'get-active-role';
export const GET_ROLE_BY_ID = 'get-role-by-id';
export const EDIT_ROLE = 'edit-role';
export const ADD_ROLE = 'add-role';
export const DELETE_ROLE = 'delete-role';
export const GET_PERMISSION = 'get-permission';

export const UPLOAD_FILE = 'upload-file';
export const UPLOAD_IMAGE = 'upload-image';
export const DELETE_FILE = 'delete-file';
export const UPLOAD_COUNCIL_IMAGE = 'upload-council-image';
export const UPLOAD_KML_FILE = 'upload-kml-file';

export const GET_SALARY_DEDUCTION_TYPES = 'get-salary-deduction-types';
export const GET_USER_DOCUMENT_TYPES = 'get-user-document-types';
export const ADD_USER = 'add-user';
export const EDIT_USER = 'edit-user';
export const GET_USER = 'get-users';
export const GET_USER_BY_ID = 'get-users-by-id';
export const GET_ACTIVE_USER = 'get-active-users';
export const GET_USERS_BY_ROLEID = 'get-users-by-roleid';
export const SEARCH_USER = 'search-user';
export const DELETE_USER = 'delete-user';
export const UNLINK_DEVICE = 'unlink-device';

export const GET_RELIGIONS = 'get-religions';

export const GET_BASE_COLOR_TREES = 'get-base-color-trees';
export const GET_BASE_COLOR_TREES_HISTORY = 'get-base-color-trees-history';
export const EDIT_BASE_COLOR_TREES = 'edit-base-color-trees';
export const DELETE_BASE_COLOR_TREES = 'delete-base-color-trees';
export const UPDATE_QC_STATUS_BASE_COLOR_TREES = 'update-qc-status-base-color-trees';
export const GET_QC_REMARKS_FOR_BASE_COLOR = 'get-qc-remark-for-base-color';
export const GET_BASE_COLOR_PENDING_QC_STATUS = 'get-base-color-pending-qc-status';
export const UPDATE_BASE_COLOR_TREE = 'update-base-color-tree';

export const GET_PROPERTY_BY_COUNCIL_ID = 'get-property-by-council-id';
export const GET_PROPERTY_BY_COUNCIL_ZONE_WARD = 'get-property-by-council-zone-ward';
export const SEARCH_PROPERTY_BY_COUNCIL_ID = 'search-property-by-council-id';
export const IMPORT_PROPERTIES = 'import-properties';
export const SHOW_PROPERTY_IMPORT_ERROR = 'show-property-import-error';
export const GET_PROPERTY_BY_COUNCIL_AND_WARD = 'get-property-by-council-and-ward';

export const GET_DENIED_ENTRY = 'get-denied-entry';

export const SHOW_LOADER = 'show-loader';
export const SHOW_LOADER_BUTTON = 'show-loader-button';

export const GET_TREE_CENSUS = 'get-tree-census';
export const GET_TREE_CENSUS_HISTORY = 'get-tree-census-history';
export const EDIT_TREE_CENSUS = 'edit-tree-census';
export const DELETE_TREE_CENSUS = 'delete-tree-census';
export const UPDATE_QC_STATUS_TREE_CENSUS = 'update-qc-status-tree-census';
export const GET_TREE_CENSUS_PENDING_QC_STATUS = 'get-tree-census-pending-qc-status';
export const UPDATE_CENSUS_TREE = 'update-census-tree';
export const REFER_TO_EXPERT = 'refer-to-expert';
// export const GET_QC_REMARKS_FOR_BASE_COLOR = "get-qc-remark-for-base-color";

export const GET_TREE_DISEASE = 'get-tree-disease';
export const GET_ACTIVE_TREE_DISEASE = 'get-active-tree-disease';
export const EDIT_TREE_DISEASE = 'edit-tree-disease';
export const ADD_TREE_DISEASE = 'add-tree-disease';
export const DELETE_TREE_DISEASE = 'delete-tree-disease';

export const GET_NO_TREE_PROPERTY = 'get-no-tree-property';
export const GET_TREE_LOCATION = 'get-tree-location';
export const GET_SPECIFIC_TREE_LOCATION_DETAILS = 'get-specific-tree-location-details';
export const SEARCH_TREES_IN_5KM = 'search-tree-in-5km';
export const GET_TREE_BY_NUMBER = 'by-tree-number';

export const GET_REPORTS = 'get-reports';

export const GET_WORK_REPORTS = 'get-work-reports';
export const GET_ALL_WORK_REPORTS = 'get-all-work-reports';
export const GET_WORK_TYPES_WORK_REPORTS = 'get-work-types-work-reports';
export const GET_WORK_CENSUS_TREE_HISTORY = 'get-work-census-tree-history';
export const GET_EXCEL_WORK_CENSUS_TREE_HISTORY = 'get-excel-work-census-tree-history';

export const GET_TREE_FAMILY = 'get-tree-family';
export const GET_ACTIVE_TREE_FAMILY = 'get-active-tree-family';
export const EDIT_TREE_FAMILY = 'edit-tree-family';
export const ADD_TREE_FAMILY = 'add-tree-family';
export const DELETE_TREE_FAMILY = 'delete-tree-family';

export const GET_CALL_LOG = 'get-callLog';
export const EXPORT_CALL_LOG = 'export-call-log';

export const DEFECIENT_TREE_NOTICE_GENERATED = 'deficient-tree-notice-generated';

/* Related to Selfie Verification */
export const GET_ALL_SELFIE = 'get-all-selfie';
export const GET_PENDING_SELFIE = 'get-pending-selfie';
export const UPDATE_PENDING_SELFIE_STATUS = 'update-pending-selfie-status';

// user location
export const GET_USER_LOCATION = 'get-user-location';
// manager
export const GET_MANAGER_ROLE = 'get-manager-role';
export const GET_MANAGER_REQUEST = 'get-manager-request';

// Council
export const GET_NEW_COUNCIL = 'get-new-council';
export const GET_NEW_ACTIVE_ZONES = 'get-new-active-zones';
export const GET_NEW_WARDS_BY_COUNCILID = 'get-new-wards-by-councilid';
export const GET_TRAVELLING_REQUEST = 'get-travelling-request';

export const GET_ROOT_LOCATION = 'get-root-location';
export const GET_TREE_CHANGES_REQUEST = 'get-tree-changes-request';

// Training
export const GET_TRAINING = 'get-training';
export const CREATE_TRAINING = 'create-training';
export const UPDATE_TRAINING = 'update-training';
export const DELETE_TRAINING = 'delete-training';

export const GET_ABELL_META_DETAILS = 'get-abell-meta-details';
export const UPDATE_META_DETAILS = 'update-meta-details';

// Tree Cutting
export const GET_STATUS = 'get-status';
export const GET_TREE_CUTTING_APPLICATIONS = 'get-tree-cutting-applications';
export const CREATE_TREE_CUTTING_APPLICATION = 'create-tree-cutting-applications';
export const GET_TREE_CUTTING_MOM = 'get-tree-cutting-mom';
export const CREATE_TREE_CUTTING_MOM = 'create-tree-cutting-mom';
export const GET_EXCEL_TREE_CUTTING_APPLICATIONS = 'get-excel-tree-cutting-applications';
export const GET_CENSUS_TREE_BY_COUNCIL_OR_PROPERTY = 'get-census-tree-by-council-or-property';
export const GET_TREE_CUTTING_COMPENSATION_FINE_DETAILS = 'get-tree-cutting-fine-details';
export const POST_TREE_CUTTING_COMPONSATION_FINE = 'post-tree-cutting-compensation-fine';
export const GET_TREE_NEED_TO_BE_PLANT_LIST = 'get-tree-need-to-be-plant-list';
export const UPDATE_TREE_NEED_TO_BE_PLANT = 'update-tree-need-to-be-plant';
export const UPDATE_TREE_IMAGE = 'update-tree-image';
export const GET_PERMISSION_LETTER_DETAILS = 'get-permission-letter-details';
export const GET_DEMANDED_TREE_CUTTING_LIST = 'get-demanded-tree-cutting-list';
export const SEARCH_TREE_CUTTING_APPLICATION = 'search-tree-cutting-application';

export const IMPORT_CENSUS_TREES = 'import-census-trees';
export const EXCEPTION_IMPORT_CENSUS_TREES = 'excpetion-import-census-trees';

export const GET_TREE_CUTTING_REASON = 'get-tree-cutting-reason';
export const UPDATE_CUTTING_REASON = 'edit-tree-cutting-reason';
export const ADD_TREE_CUTTING_REASON = 'add-tree-cutting-reason';
export const DELETE_TREE_CUTTING_REASON = 'delete-tree-cutting-reason';
export const GET_TREE_CUTTING_REASON_LIST = 'get-tree-cutting-reason-list';
export const TREE_CUTTING_REASON_STATUS_CHANGE = 'tree-cutting-reason-status-change';
export const GET_SITEINSCPECTOR = 'get_siteinscpector';
export const ADD_SITEINSCPECTOR = 'add_siteinscpector';
export const GET_TREE_CUTTING_APPLICATION_BY_ID = 'get_tree_cutting_application_by_id';
export const UPDATE_TREE_CUTTING_APPLICATION = 'update_tree_cutting_application';
// meeting create and view
export const ADD_MEETING = 'add-meeting';
export const UPDATE_MEETING = 'update-meeting';
export const CANCEL_MEETING = 'cancel-meeting';
export const GET_MEETINGS = 'get-meetings';
export const EXCEL_MEETINGS_REPORT = 'excel-meeting-report';
export const GET_MEETING_BY_ID = 'get-meetings-by-id';
export const GET_ATTENDEES = 'get-attendees';
export const ADD_APPLICATION_TO_MEETING = 'add-application-to-meeting';
export const GET_FUTURE_MEETINGS = 'get-future-meetings';
export const DETAILS_MEETING_BY_ID = 'detail-meeting-by-id';
export const ADD_MEETING_REMARK = 'add_meeting_remark';

// news paper
export const GET_NEWS_PAPERS = 'get_news_papers';
export const ADD_NEWS_PAPER = 'add_news_paper';
export const GET_NEWS_PAPER_BY_ID = 'get_news_paper_by_id';
export const UPDATE_NEWS_PAPER = 'update_news_paper';
export const DELETE_NEWS_PAPER = 'delete_news_paper';
export const SEARCH_NEWS_PAPER = 'search_news_paper';

// newspaper_proclamation
export const ADD_NEWSPAPER_PROCLAMATION = 'add_newspaper_proclamation';
export const UPLOAD_PROCLAMATION_RECIPT = 'upload_proclamation_receipt';
export const ADD_PROCLAMATION_OBJECTION = 'add_proclamation_objections';


export const ADD_DEPOSITE_AMOUNT_RECEIPT = "add_deposite_amount_receipt"

export const GET_TREE_REPLANATION_DETAILS_BY_APPLICATION_BY_ID = 'get_tree_replantation_by_application_by_id';

// upcoming replantation

export const GET_UPCOMING_REPLANTATION_DETAILS = "get_upcoming_replantation_details";
export const ASSIGN_REPLANTATION = "assign_replantation";